import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from './contexts/UserContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

console.log(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY); // This should log your Stripe key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Profile = () => {
  const { user, token } = useUser();
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    if (user) {
      axios.get(`https://diabetesselfhelpgroup.co.uk//users/${user.userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        setProfile(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Failed to fetch profile:', error);
        setLoading(false);
      });

      axios.post('https://diabetesselfhelpgroup.co.uk/api/create-setup-intent', {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        setClientSecret(response.data.clientSecret);
      })
      .catch(error => {
        console.error('Failed to create setup intent:', error);
      });
    }
  }, [user, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`https://diabetesselfhelpgroup.co.uk/users/${user.userId}`, profile, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      setProfile(response.data);
      alert('Profile updated');
    })
    .catch(error => {
      console.error('Failed to update profile:', error);
    });
  };

  if (loading) return <p className="text-center mt-4">Loading...</p>;

  return (
    <div className="max-w-3xl mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-3xl font-bold mb-6 text-center">User Profile</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="form-group">
          <label htmlFor="username" className="block text-sm font-medium text-gray-700">Name:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={profile.username || ''}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={profile.email || ''}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="form-group">
          <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address:</label>
          <input
            type="text"
            id="address"
            name="address"
            value={profile.address || ''}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="form-group">
          <label htmlFor="shippingAddress" className="block text-sm font-medium text-gray-700">Shipping Address:</label>
          <input
            type="text"
            id="shippingAddress"
            name="shippingAddress"
            value={profile.shippingAddress || ''}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="form-group">
          <label htmlFor="shippingCity" className="block text-sm font-medium text-gray-700">Shipping City:</label>
          <input
            type="text"
            id="shippingCity"
            name="shippingCity"
            value={profile.shippingCity || ''}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="form-group">
          <label htmlFor="shippingCounty" className="block text-sm font-medium text-gray-700">Shipping County:</label>
          <input
            type="text"
            id="shippingCounty"
            name="shippingCounty"
            value={profile.shippingCounty || ''}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="form-group">
          <label htmlFor="shippingPostcode" className="block text-sm font-medium text-gray-700">Shipping Postcode:</label>
          <input
            type="text"
            id="shippingPostcode"
            name="shippingPostcode"
            value={profile.shippingPostcode || ''}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="form-group">
          <label htmlFor="billingAddress" className="block text-sm font-medium text-gray-700">Billing Address:</label>
          <input
            type="text"
            id="billingAddress"
            name="billingAddress"
            value={profile.billingAddress || ''}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="form-group">
          <label htmlFor="billingCity" className="block text-sm font-medium text-gray-700">Billing City:</label>
          <input
            type="text"
            id="billingCity"
            name="billingCity"
            value={profile.billingCity || ''}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="form-group">
          <label htmlFor="billingCounty" className="block text-sm font-medium text-gray-700">Billing County:</label>
          <input
            type="text"
            id="billingCounty"
            name="billingCounty"
            value={profile.billingCounty || ''}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="form-group">
          <label htmlFor="billingPostcode" className="block text-sm font-medium text-gray-700">Billing Postcode:</label>
          <input
            type="text"
            id="billingPostcode"
            name="billingPostcode"
            value={profile.billingPostcode || ''}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <button type="submit" className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700">
          Save
        </button>
      </form>
      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <PaymentMethodManager />
        </Elements>
      )}
    </div>
  );
};

const PaymentMethodManager = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: 'https://diabetesselfhelpgroup.co.uk/profile',
      },
    });

    if (result.error) {
      console.error(result.error.message);
    } else {
      alert('Payment method added successfully');
    }
  };

  return (
    <div className="mt-6">
      <h3 className="text-xl font-semibold mb-4">Payment Methods</h3>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button
          type="submit"
          className="mt-4 py-2 px-4 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700"
          disabled={!stripe || !elements}
        >
          Add Payment Method
        </button>
      </form>
    </div>
  );
};

export default Profile;
